import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Heading,
  Flex,
  Text,
  IconButton,
  Button,
  useColorMode,
  Image,
  useToast,
  Modal,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select as ChakraSelect,
  FormControl,
  FormLabel,
  VStack,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import Select from 'react-select';
import config from '../config';
import './Home.css';

const Home = () => {
  const { userName } = useAuth();
  const [integrations, setIntegrations] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false); // Controla se o popup está aberto
const [integrationToDelete, setIntegrationToDelete] = useState(null); // Armazena a integração que será deletada
const cancelRef = useRef(); // Referência para o botão "Cancelar" no AlertDialog

  const [numbers, setNumbers] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [integrationName, setIntegrationName] = useState('');
  const [integrationPlatform, setIntegrationPlatform] = useState('');
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  
  const { colorMode } = useColorMode(); 
  const [theme, setTheme] = useState(localStorage.getItem('theme') || colorMode); 

  useEffect(() => {
    fetchIntegrations();
    fetchNumbers();
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const fetchIntegrations = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${config.apiUrl}/api/integrations`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIntegrations(response.data);
    } catch (error) {
      console.error('Failed to fetch integrations:', error);
    }
  };

  const fetchNumbers = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${config.apiUrl}/api/numbers`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setNumbers(response.data.filter(number => number.status === 'Active'));
    } catch (error) {
      console.error('Failed to fetch numbers:', error);
    }
  };

  const defaultMessages = [
    {
      category: '[Pix] Aguardando Pagamento',
      content: 'Olá, ${contactFirstName}! 🥰\n\nVi que começou a comprar o produto ${productName} por pix.\n\nEstou enviando aqui abaixo o código pix para finalizar sua compra\n\nCopie o código abaixo por inteiro (não só a parte azul)'
    },
    {
      category: '[Pix] Aguardando Pagamento',
      content: '${pix}'
    },
    {
      category: 'Compra Recusada',
      content: '⚠ Oops! Algo deu errado com o seu pagamento. 😔 Parece que houve um problema com o cartão ou o tempo do PIX expirou.\n\nEncontre novamente seu produto aqui👇\n[DIGITE AQUI O LINK DO SEU SITE/PÁGINA]\nou envie um e-mail para\n[DIGITE AQUI O SEU EMAIL]'
    },
    {
      category: 'Compra Aprovada',
      content: 'Olá, ${contactFirstName}! 🎉 Pode comemorar, a sua compra foi aprovada! ${productName}\n\n✅ Como acessar sua compra, clicando nesse link abaixo:\nhttps://dashboard.kiwify.com.br/login\n\n👉🏼 Atenção! USE esse e-mail para login ${Customer.email}\n\n* Se necessário, crie uma nova senha na plataforma. Seu acesso também foi enviado para seu e-mail, cheque também a caixa de spams.\n\n🥰 Ainda precisa de SUPORTE? Envie e-mail para: [DIGITE AQUI SEU EMAIL]​ ou veja nossa central de ajuda👇\n[DIGITE AQUI O LINK DO SEU SITE OU CENTRAL DE AJUDA]'
    },
    {
      category: 'Reembolso Efetuado',
      content: '🛑Reembolso realizado! ☹ownn, o que aconteceu para você desistir da sua compra? \n\nDeixe-nos saber do seu desgosto! conte aqui👇'
    },
    {
      category: 'Carrinho Abandonado',
      content: 'Olá, ${contactFirstName}!\n\n[SEU NOME] aqui 🥰. Vi que começou a comprar ${productName}\n\n🔴 Quer que envie o link novamente? Veja se ele se encontra aqui abaixo👇\n\n👉🏼 [DIGITE O LINK DO SEU SITE COM TODOS OS PRODUTOS]'
    }
  ];
  

  const handleAddIntegration = async () => {
    try {
      const token = localStorage.getItem('authToken');
  
      const response = await axios.post(
        `${config.apiUrl}/api/integrations`,
        { name: integrationName, platform: integrationPlatform, numbers: selectedNumbers },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const integrationId = response.data._id;
  
      for (const message of defaultMessages) {
        await axios.post(
          `${config.apiUrl}/api/integrations/${integrationId}/messages`,
          {
            category: message.category,
            content: message.content
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }
  
      toast({
        title: 'Integração criada com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
  
      navigate(`/edit/${integrationId}`);
    } catch (error) {
      console.error('Erro ao criar integração:', error);
      toast({
        title: 'Erro ao criar integração.',
        description: error.response ? error.response.data.error : 'Erro de rede',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };
  
  const handleEditIntegration = (id) => {
    navigate(`/edit/${id}`);
  };

  const openDeleteConfirmation = (id) => {
    setIntegrationToDelete(id); // Armazena o ID da integração que será excluída
    setIsAlertOpen(true); // Abre o `AlertDialog`
  };
  
  const handleDeleteIntegration = async () => {
    try {
      const token = localStorage.getItem('authToken');
      await axios.delete(`${config.apiUrl}/api/integrations/${integrationToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsAlertOpen(false); // Fecha o popup
      fetchIntegrations(); // Atualiza a lista de integrações
      toast({
        title: 'Integração deletada com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    } catch (error) {
      console.error('Erro ao deletar integração:', error);
      toast({
        title: 'Erro ao deletar integração.',
        description: error.response ? error.response.data.error : 'Erro de rede',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };
  

  const getPlatformIcon = (platform) => {
    switch (platform) {
      case 'Cakto':
        return '/img/flag/cakto.png';
      case 'Kiwify':
        return '/img/flag/kiwify.png';
        case 'Eduzz':
        return '/img/flag/eduzz.png';
        case 'Kirvano':
        return '/img/flag/kirvano.png';
      case 'Hotmart':
        return '/img/flag/hotmart.png';
      default:
        return null;
    }
  };

  const handleSelectChange = (selectedOptions) => {
    const allNumbersSelected = selectedOptions.some(option => option.value === 'all');
    if (allNumbersSelected) {
      setSelectedNumbers(['all']);
    } else {
      setSelectedNumbers(selectedOptions.map(option => option.value));
    }
  };

  const numberOptions = [
    ...numbers.map(number => ({ value: number.number, label: number.number }))
  ];

  return (
    <Flex
      className='novoapp'
      direction="column"
      height="100vh"
      bg={theme === 'light' ? '#f4f4f4' : 'transparent'}
      style={{
        backgroundImage: theme === 'dark' ? 'url(/img/bg.jpg)' : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Navbar />
      <Box className='novoapxp' flex="1" p={10} ml="290px">
        <Flex className='rounderdx' justifyContent="space-between" alignItems="center" mb={8}>
        <div className='classn' style={{ width: '396px', display: 'flex' }}><Image src="/img/iconintegra.png" alt="WhatsApp" boxSize="40px" mr={2} />
          <Heading className='textsx x' as="h2" size="lg" color={theme === 'light' ? '#000000' : 'white'}>Integrações</Heading></div> 
        
          <Button
  variant="ghost"
  onClick={onOpen}
  display="flex"
  alignItems="center"
  justifyContent="center"
  _hover={{ background: 'none' }}
  _active={{ background: 'none' }}
>
  <Icon
    as={FaPlus}
    boxSize={34}
    color="green.500"
    border="2px solid"
    borderRadius="full"
    padding="4px"
  />
  <Text className='montserrat color' marginLeft="8px" color="green.500">
  Adicionar Integração
  </Text>
</Button>
        </Flex>

        <Box bg={theme === 'light' ? '#ffffff' : '#ffffff'} p={6} className='rounderd' rounded="md" boxShadow="md" mx="auto" maxWidth="1200px">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th className='montserrat fonts' color={theme === 'light' ? 'gray.700' : 'white'}>Nome</Th>
                <Th className='montserrat fonts' color={theme === 'light' ? 'gray.700' : 'white'}>Plataforma</Th>
                <Th className='montserrat fonts' color={theme === 'light' ? 'gray.700' : 'white'}>Opções</Th>
              </Tr>
            </Thead>
            <Tbody>
              {integrations.map((integration) => (
                <Tr key={integration._id}>
                  <Td className='montserrat' color={theme === 'light' ? 'gray.700' : 'white'}>{integration.name}</Td>
                  <Td>
                    <Image src={getPlatformIcon(integration.platform)} alt={integration.platform} className='imglogosx' />
                  </Td>
                  <Td>
                    <Flex>
                      <IconButton className='btos'
                        aria-label="Edit Integration"
                        mr={2}
                        onClick={() => handleEditIntegration(integration._id)}
                      >
                        <img className='iconeimg' src="/img/edit.png"></img>
                        </IconButton>
                      <IconButton
                        className='btos'
                        aria-label="Delete Integration"
                        onClick={() => openDeleteConfirmation(integration._id)}
                      >
                         <img className='iconeimg' src="/img/lixo.png"></img>
                        </IconButton>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <AlertDialog
  isOpen={isAlertOpen}
  leastDestructiveRef={cancelRef}
  onClose={() => setIsAlertOpen(false)}
>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Deletar Integração
      </AlertDialogHeader>

      <AlertDialogBody>
        Tem certeza que deseja deletar esta integração? Esta ação não pode ser desfeita.
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
          Cancelar
        </Button>
        <Button colorScheme="red" onClick={handleDeleteIntegration} ml={3}>
          Deletar
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={"white"}>
          <ModalHeader>Adicionar Integração</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Nome da Integração</FormLabel>
                <Input value={integrationName} onChange={(e) => setIntegrationName(e.target.value)} />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Plataforma</FormLabel>
                <ChakraSelect bg={"white"} onChange={(e) => setIntegrationPlatform(e.target.value)}>
                  <option bg={theme === 'light' ? 'white' : 'white'} value="">Escolha uma plataforma</option>
                  <option bg={theme === 'light' ? 'white' : 'white'} value="Kiwify">Kiwify</option>
                  <option bg={theme === 'light' ? 'white' : 'white'} value="Cakto">Cakto</option>
                  <option bg={theme === 'light' ? 'white' : 'white'} value="Eduzz">Eduzz</option>
                  <option bg={theme === 'light' ? 'white' : 'white'} value="Kirvano">Kirvano</option>
                  <option bg={theme === 'light' ? 'white' : 'white'} value="Hotmart">Hotmart</option>
                </ChakraSelect>
              </FormControl>
              <FormControl>
                <FormLabel>Números</FormLabel>
                <Select
                  isMulti
                  options={numberOptions}
                  onChange={handleSelectChange}
                  value={numberOptions.filter(option => selectedNumbers.includes(option.value))}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button className='noatuars' colorScheme="teal" onClick={handleAddIntegration}>
              Adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Home;
