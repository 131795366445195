import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const ScanQRCode = ({ match }) => {
  const [qrCode, setQrCode] = useState('');
  const [logs, setLogs] = useState([]);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const socket = io('https://webhooks.simplificazap.com.br');

    socket.emit('StartConnection', match.params.id);

    socket.on('message', (msg) => {
      setLogs((prevLogs) => [...prevLogs, msg]);
    });

    socket.on('qr', (src) => {
      setQrCode(src);
    });

    socket.on('ready', (data) => {
      setConnected(true);
    });

    return () => {
      socket.disconnect();
    };
  }, [match.params.id]);

  const handleLogout = () => {
    const socket = io('https://webhooks.simplificazap.com.br');
    socket.emit('LogoutDevice', match.params.id);
  };

  return (
    <div className="container">
      <h4 className="text-center py-3">WAF</h4>
      <div className="row">
        <div className="col-sm-6">
          <p className="small">Por favor, recarregue se o QR code não aparecer </p>
          {qrCode && <img src={qrCode} alt="QR Code" id="qrcode" />}
        </div>
        <div className="col-sm-6">
          {connected ? (
            <div>
              <button className="btn btn-danger mb-3" onClick={handleLogout}>Logout</button>
            </div>
          ) : (
            <div>
              <span>Waiting for connection...</span>
            </div>
          )}
          <h5>Logs:</h5>
          <ul className="logs">
            {logs.map((log, index) => (
              <li key={index}>{log}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ScanQRCode;
