import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Link,
  VStack,
  Image,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Spacer,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';
import { NavLink as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faPhone,
  faChartBar,
  faSignOutAlt,
  faClipboard,
  faSignal,
  faUserEdit,
  faQuestionCircle,
  faCog,
  faStar,
  faBars,
  faMoon,
  faSun
} from '@fortawesome/free-solid-svg-icons'; 
import { useAuth } from '../context/AuthContext';
import './Navbar.css';
import { FaWhatsappSquare } from 'react-icons/fa';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newTheme); // Salva o novo tema no localStorage
    window.location.reload(); // Recarrega a página para aplicar o novo tema
  };
  

  const handleLogout = () => {
    onOpen();
  };

  const confirmLogout = () => {
    onClose();
    navigate('/logout');
  };

  const getLinkStyle = (path) => {
    return location.pathname === path
      ? { bgGradient: theme === 'dark'
        ? 'linear(to-r, #515153, #3c3c3e)'  // Gradiente para o tema escuro
        : 'linear(to-r, #f0f0f0, #e0e0e0)' }
      : {};
  };

  const { userName } = useAuth();

  const formatUserName = (fullName) => {
    const nameParts = fullName.split(' ');
    if (nameParts.length > 1) {
      return `${nameParts[0]} ${nameParts[1][0]}.`;
    }
    return fullName;
  };

  const userNameFormatado = formatUserName(userName);

  return (
    <>
      {/* Navbar horizontal para dispositivos móveis */}
      <Flex
        as="nav"
        position="fixed"
        top={0}
        left={0}
        right={0}
        bg={theme === 'dark' ? '#201f23' : '#f7f7f7'}
        zIndex="1100"
        p={4}
        alignItems="center"
        justifyContent="space-between"
        display={{ base: 'flex', md: 'none' }}
      >
        <Image className='logomob' src={theme === 'dark' ? '/img/logo.svg' : '/img/logo-light.svg'} alt="Logo" boxSize="40px" objectFit="contain" />
        <IconButton
          icon={<FontAwesomeIcon icon={faBars} />}
          color="white"
          onClick={onDrawerOpen}
          zIndex="1100"
        />
      </Flex>

      {/* Sidebar para desktop (removida em dispositivos móveis) */}
      <Box
        position="fixed"
        overflowX="hidden"
        left={0}
        top={0}
        h="100vh"
        w={{ base: '0', md: '290px' }}
        bg={theme === 'dark' ? '#201f23' : '#ffffff'}
        color={theme === 'dark' ? 'white' : 'black'}
        boxShadow="xl"
        p={4}
        display={{ base: 'none', md: 'block' }}
      >
        <Flex className='menuhorizontals' direction="column" h="100%" align="center">
          {/* Logo */}
          <Link
            as={RouterLink}
            to="/numeros"
            px={4}
            py={2}
            color={theme === 'dark' ? 'white' : 'black'}
            borderRadius="md"
            display="flex"
            alignItems="center"
            fontWeight="500"
            fontSize="md"
          >
            <Image className="logonavbar" src={theme === 'dark' ? '/img/logo.svg' : '/img/logo-light.svg'} alt="Logo" mb={2} boxSize="92px" objectFit="contain" />
          </Link>

          {/* Plaquinha */}
          <Link
            as={RouterLink}
            to="https://app-vlc.hotmart.com/affiliate-recruiting/view/0070S95178117"
            borderRadius="md"
            display="flex"
            alignItems="center"
            fontWeight="500"
            fontSize="md"
          >
            <Flex alignItems="center" mt={2} mb={6} bg={theme === 'light' ? '#6aab6d' : 'white'} borderRadius="10px" px={4} py={2} boxShadow="md">
            <Box as="span" mr={2}>
  <svg
    className='svgicon'
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122 128"
    width="25"
    height="20"
    fill={theme === 'light' ? '#yellow' : '#6aab6d'}
  >
    <defs>
      <clipPath id="cp1">
        <path d="M1.73 7.54h119.72v113.92H1.73z" />
      </clipPath>
      <clipPath id="cp2">
        <path d="M61.5 7.54l14.11 43.51h45.66l-36.94 26.9 14.11 43.51-36.94-26.89-36.94 26.89 14.11-43.51-36.94-26.9h45.66z" />
      </clipPath>
    </defs>
    <g clipPath="url(#cp1)">
      <g>
        <g clipPath="url(#cp2)">
          <g>
            <path
              d="M1.7 7.5h119.7v114H1.7z"
              fill={theme === 'light' ? 'white' : '#6aab6d'}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</Box>
              <Text className='opensans' fontWeight="500" color={theme === 'light' ? 'white' : '#6aab6d'} fontSize="15">SEJA AFILIADO</Text>
              <Box ml={3} bg={theme === 'light' ? 'white' : '#6aab6d'} color={theme === 'light' ? '#6aab6d' : 'white'} fontSize="10" borderRadius="2" px={1} py={0.2}>NOVO</Box>
            </Flex>
          </Link>

          {/* Menu */}
          <VStack className='menudesk' align="stretch" spacing={3} w="100%">
          <Text className='opensanss left bottom' fontSize="16px" color={theme === 'dark' ? 'white' : 'black'}  fontWeight="700">MENU</Text>

            <Link
              className='top menuclass'
              as={RouterLink}
              to="/numeros"
              _hover={{
                bgGradient: theme === 'dark'
                  ? 'linear(to-r, #515153, #3c3c3e)'  // Gradiente para o tema escuro
                  : 'linear(to-r, #f0f0f0, #e0e0e0)', // Gradiente para o tema claro
              }}
              color={theme === 'dark' ? 'white' : 'black'}
              px={4}
              py={2}
              borderRadius="md"
              display="flex"
              alignItems="center"
              fontWeight="500"
              fontSize="md"
              {...getLinkStyle('/numeros')}
            >
              <FontAwesomeIcon className='iconmenu'  fontSize="28px" color='#9ac79c' icon={faWhatsapp} style={{ marginRight: '10px' }} />
              <Text>WhatsApp</Text>
            </Link>
            <Link
              as={RouterLink}
              to="/home"
              _hover={{
                bgGradient: theme === 'dark'
                  ? 'linear(to-r, #515153, #3c3c3e)'  // Gradiente para o tema escuro
                  : 'linear(to-r, #f0f0f0, #e0e0e0)', // Gradiente para o tema claro
              }}
              px={4}
              py={2}
              color={theme === 'dark' ? 'white' : 'black'}
              borderRadius="md"
              display="flex"
              alignItems="center"
              className='menuclass'
              fontWeight="500"
              fontSize="md"
              {...getLinkStyle('/home')}
            >
              <FontAwesomeIcon className='iconmenu'  fontSize="25px" color='#9ac79c' icon={faHome} style={{ marginRight: '10px' }} />
              <Text>Integração</Text>
            </Link>
            <Link
              as={RouterLink}
              to="/dashboard"
              _hover={{
                bgGradient: theme === 'dark'
                  ? 'linear(to-r, #515153, #3c3c3e)'  // Gradiente para o tema escuro
                  : 'linear(to-r, #f0f0f0, #e0e0e0)', // Gradiente para o tema claro
              }}
              px={4}
              py={2}
              className='menuclass dashboard'
              borderRadius="md"
              display="flex" 
              alignItems="center"
              color={theme === 'dark' ? 'white' : 'black'}
              fontWeight="500"
              fontSize="md"
              {...getLinkStyle('/dashboard')}
            >
              <FontAwesomeIcon className='iconmenu'  fontSize="25px" color='#9ac79c' icon={faSignal} style={{ marginRight: '10px' }} />
              <Text>Dashboard</Text>
            </Link>
          </VStack>

          {/* Seção de Ajuda */}
          <Box className="help" w="100%" mt={9}>
            <Text className='opensanss left' fontSize="16px" color={theme === 'dark' ? 'white' : 'black'} mb={3} fontWeight="700" lineHeight="shorter">TUTORIAIS</Text>
            <VStack align="stretch" spacing={2} w="100%">
              <Link
                as={RouterLink}
                to="https://simplifiquemodeloss.com.br/simplificazap-central-de-ajuda/"
                target='_blank'
                _hover={{
                  bgGradient: theme === 'dark'
                    ? 'linear(to-r, #515153, #3c3c3e)'  // Gradiente para o tema escuro
                    : 'linear(to-r, #f0f0f0, #e0e0e0)', // Gradiente para o tema claro
                }}
                px={4}
                py={2}
                borderRadius="md"
                display="flex"
                alignItems="center"
                fontWeight="500"
                fontSize="16px"
                color={theme === 'dark' ? 'white' : 'black'}
                {...getLinkStyle('/central-de-ajuda')}
              >
                <FontAwesomeIcon color='#9ac79c' fontSize="22px" icon={faQuestionCircle} style={{ marginRight: '10px' }} />
                <Text className='opensanss'>Central de ajuda</Text>
              </Link>
              <Link
                as={RouterLink}
                to="https://stats.uptimerobot.com/phD34ALkKX"
                target="_blank"
                _hover={{
                  bgGradient: theme === 'dark'
                    ? 'linear(to-r, #515153, #3c3c3e)'  // Gradiente para o tema escuro
                    : 'linear(to-r, #f0f0f0, #e0e0e0)', // Gradiente para o tema claro
                }}
                px={4}
                py={2}
                borderRadius="md"
                display="flex"
                alignItems="center"
                className='fontOpen Sans'
                fontWeight="500"
                fontSize="16px"
                color={theme === 'dark' ? 'white' : 'black'}
                {...getLinkStyle('/falar-com-atendente')}
              >
                <FontAwesomeIcon color='#9ac79c' icon={faCog} fontSize="20px" style={{ marginRight: '10px' }} />
                <Text className='opensanss'>Status do Sistema</Text>
              </Link>
            </VStack>
          </Box>

          
          <Box className='classic' mt={8} cursor="pointer" onClick={toggleTheme}>
      {theme === 'light' ? (
        // SVG do botão Lua
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 36" width="55" height="36">
          <title>Modo Escuro</title>
          <style>
            {`.s89898 { fill: #558e57 !important } .s1 { fill: #6aab6d } .s2 { fill: #000000 }`}
          </style>
          <path id="Layer" className="s89898" d="m35.7 7.7c5.9 0 10.8 5 10.8 10.9 0 5.9-4.9 10.8-10.8 10.8h-20.8c-5.9 0-10.9-4.9-10.9-10.8 0-5.9 5-10.9 10.9-10.9z" />
          <path id="Layer" className="s1" d="m35.7 3.2c-8.4 0-15.4 7-15.4 15.4 0 8.3 7 15.3 15.4 15.3 8.3 0 15.3-7 15.3-15.3 0-8.4-7-15.4-15.3-15.4z" />
          <path id="Layer" className="s2" d="m45.6 19.4c-0.3 3.9-3 7.3-6.7 8.6-3.7 1.3-8 0.1-10.6-2.8-2.7-2.9-3.3-7.2-1.7-10.8 1.7-3.5 5.3-5.8 9.2-5.8-2.8 6.2 3.3 13 9.8 10.8z" />
        </svg>
      ) : (
        // SVG do botão Sol
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 36" width="55" height="36">
          <title>Modo Claro</title>
          <style>
            {`.s89898 { fill: #558e57 !important } .s1 { fill: #6aab6d } .s2 { fill: #000000 }`}
          </style>
          <path id="Layer" className="s89898" d="m41.7 7.7c5.9 0 10.8 5 10.8 10.9 0 5.9-4.9 10.8-10.8 10.8h-20.8c-5.9 0-10.9-4.9-10.9-10.8 0-5.9 5-10.9 10.9-10.9z" />
          <path id="Layer" className="s1" d="m16.7 3.2c-8.4 0-15.4 7-15.4 15.4 0 8.3 7 15.3 15.4 15.3 8.3 0 15.3-7 15.3-15.3 0-8.4-7-15.4-15.3-15.4z" />
          <path id="XMLID_922_" className="s2" d="m16.7 24.5c-3.4 0-6.2-2.8-6.2-6.2 0-3.5 2.8-6.3 6.2-6.3 3.5 0 6.2 2.8 6.2 6.3 0 3.4-2.7 6.2-6.2 6.2z" />
          <path id="XMLID_1397_" fillRule="evenodd" className="s2" d="m28.3 21.3c0.1 0.2 0.2 0.5 0.1 0.8-0.1 0.2-0.3 0.4-0.5 0.5l-3.7 1.1 0.1 3.8c0 0.3-0.2 0.6-0.4 0.7-0.2 0.2-0.5 0.2-0.7 0.1l-3.6-1.2-2.2 3.1c-0.2 0.2-0.4 0.3-0.7 0.3-0.2 0-0.5-0.1-0.6-0.3l-2.3-3.1-3.6 1.2c-0.2 0.1-0.5 0.1-0.7-0.1-0.2-0.1-0.3-0.4-0.3-0.7v-3.8l-3.6-1.1c-0.3-0.1-0.5-0.3-0.6-0.5 0-0.3 0-0.6 0.2-0.8l2.2-3-2.2-3.1c-0.2-0.2-0.2-0.5-0.2-0.7 0.1-0.3 0.3-0.5 0.6-0.5l3.6-1.2v-3.8c0-0.3 0.1-0.5 0.3-0.7 0.2-0.1 0.5-0.2 0.7-0.1l3.6 1.3 2.3-3.2c0.1-0.2 0.4-0.3 0.6-0.3 0.3 0 0.5 0.1 0.7 0.3l2.2 3.2 3.6-1.3c0.2-0.1 0.5 0 0.7 0.1 0.2 0.2 0.4 0.4 0.4 0.7l-0.1 3.8 3.7 1.2c0.2 0 0.4 0.2 0.5 0.5 0.1 0.2 0 0.5-0.1 0.7l-2.3 3.1z" />
        </svg>
      )}
    </Box>
          {/* Perfil do Usuário */}
          <Menu>
            <MenuButton
              as={Flex}
              align="center"
              borderRadius="md"
              w="100%"
              h="100%"
              p={3}
              mt={8}
              cursor="pointer"
              
            >
              <Box ml={3} textAlign="center">
                <Text className="nomen" fontWeight="500">{userNameFormatado}</Text>
              </Box>
            </MenuButton>
            <MenuList className="bgnews" bgGradient="linear(to-r, #515153, #3c3c3e)" borderColor='linear(to-r, #515153, #3c3c3e)'>
              <MenuItem className='newsx' icon={<FontAwesomeIcon icon={faUserEdit} />} _hover={{ bg: 'teal.600' }}>
                Editar perfil
              </MenuItem>
              <MenuItem
                as={RouterLink}
                to="/minha-assinatura"
                icon={<FontAwesomeIcon icon={faClipboard} />}
                _hover={{ bgGradient: 'linear(to-r, #515153, #3c3c3e)' }}
              >
                Minha assinatura
              </MenuItem>
              <MenuItem
                className='newsx'
                icon={<FontAwesomeIcon icon={faUserEdit} />}
                _hover={{ bgGradient: 'linear(to-r, #515153, #3c3c3e)' }}
              >
                Editar perfil
              </MenuItem>
              <MenuItem
                icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                _hover={{ bgGradient: 'linear(to-r, #515153, #3c3c3e)' }}
                onClick={handleLogout}
              >
                Deslogar
              </MenuItem>
            </MenuList>
          </Menu>

          {/* Seletor de Tema */}
         
        </Flex>
      </Box>

      {/* Modal de Confirmação de Logout */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white" fontFamily="'Poppins', sans-serif">
          <ModalHeader>Confirmar Logout</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Você realmente deseja sair?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={confirmLogout}>
              Sim
            </Button>
            <Button variant="red" onClick={onClose}>Não</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Drawer para dispositivos móveis com fullscreen */}
      <Drawer isOpen={isDrawerOpen} placement="top" onClose={onDrawerClose} size="full">
        <DrawerOverlay />
        <DrawerContent bg={theme === 'dark' ? '#201f23' : '#f7f7f7'} zIndex="1100">
          <DrawerHeader borderBottomWidth="1px" borderBottomColor="gray.700">
            <Flex justify="space-between" align="center">
              <Image className='logomob' src={theme === 'dark' ? '/img/logo.svg' : '/img/logo-light.svg'} alt="Logo" boxSize="72px" objectFit="contain" />
              <IconButton
                icon={<FontAwesomeIcon icon={faBars} />}
                colorScheme="teal"
                onClick={onDrawerClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody bg={theme === 'dark' ? '#201f23' : '#f7f7f7'} display="flex" justifyContent="center" alignItems="center">
            <VStack spacing={8} textAlign="center">
              <Link
                as={RouterLink}
                to="/numeros"
                onClick={onDrawerClose}
                _hover={{ bgGradient: 'linear(to-r, #515153, #3c3c3e)' }}
                color={theme === 'dark' ? 'white' : 'black'}
                fontSize="3xl"
                fontWeight="bold"
                {...getLinkStyle('/numeros')}
              >
                <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '10px' }} />
                WhatsApp
              </Link>
              <Link
                as={RouterLink}
                to="/home"
                color={theme === 'dark' ? 'white' : 'black'}
                onClick={onDrawerClose}
                _hover={{ bgGradient: 'linear(to-r, #515153, #3c3c3e)' }}
                fontSize="3xl"
                fontWeight="bold"
                {...getLinkStyle('/home')}
              >
                <FontAwesomeIcon icon={faHome} style={{ marginRight: '10px' }} />
                Integração
              </Link>
              <Link
                as={RouterLink}
                className='dashboard'
                to="/dashboard"
                color={theme === 'dark' ? 'white' : 'black'}
                onClick={onDrawerClose}
                _hover={{ bgGradient: 'linear(to-r, #515153, #3c3c3e)' }}
                fontSize="3xl"
                fontWeight="bold"
                {...getLinkStyle('/dashboard')}
              >
                <FontAwesomeIcon icon={faCog} style={{ marginRight: '10px' }} />
                Dashboard
              </Link>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Navbar;
