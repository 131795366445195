export const decodeJWT = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    const decoded = JSON.parse(jsonPayload);
    console.log('Decoded JWT:', decoded); // Adicione este log
    return decoded;
  } catch (error) {
    console.error('Invalid JWT:', error);
    return null;
  }
};

export const isTokenValid = (token) => {
  const decodedToken = decodeJWT(token);
  if (!decodedToken) {
    return false;
  }

  // Check if the token is expired
  const currentTime = Date.now() / 1000;
  if (decodedToken.exp < currentTime) {
    return false;
  }

  return true;
};
