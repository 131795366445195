import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Heading,
  Flex,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
  Button,
  SimpleGrid,
  IconButton,
  GridItem,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  Center,
  FormControl,
  FormLabel,
  Image,
} from '@chakra-ui/react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import io from 'socket.io-client';
import Navbar from '../components/Navbar';
import './Numbers.css';

const ManageNumbers = () => {
  const [numbers, setNumbers] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [numberToDelete, setNumberToDelete] = useState(null);
  const cancelRef = useRef();

  const [newNumber, setNewNumber] = useState('');
  const [ddi, setDdi] = useState('55');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const toast = useToast();

  useEffect(() => {
    fetchNumbers();
    const socket = io('https://webhooks.simplificazap.com.br');

    const handleMessage = async (event) => {
      if (event.data === 'whatsapp-connected') {
        setIsQrModalOpen(false);
        toast({
          title: 'Número autenticado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        try {
          const token = localStorage.getItem('authToken');
          await axios.post(
            'https://webhooks.simplificazap.com.br/api/update-status',
            { number: selectedNumber, status: 'Active' },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          fetchNumbers();
        } catch (error) {
          console.error("Erro ao atualizar o status para 'Active': ", error);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      socket.disconnect();
    };
  }, [selectedNumber]);

  const fetchNumbers = async () => {
    const token = localStorage.getItem('authToken');
    const res = await axios.get('https://webhooks.simplificazap.com.br/api/numbers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setNumbers(res.data);
  };

  const handleAddNumber = async () => {
    const token = localStorage.getItem('authToken');
    await axios.post(
      'https://webhooks.simplificazap.com.br/api/numbers',
      { number: `${ddi}${newNumber}` },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setNewNumber('');
    setDdi('55');
    fetchNumbers();
    setIsAddModalOpen(false);
  };

  const openDeleteConfirmation = (id) => {
    setNumberToDelete(id);
    setIsAlertOpen(true);
  };

  const handleDeleteNumber = async () => {
    const token = localStorage.getItem('authToken');
    await axios.delete(`https://webhooks.simplificazap.com.br/api/numbers/${numberToDelete}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setIsAlertOpen(false);
    fetchNumbers();
    toast({
      title: 'Número deletado com sucesso!',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleShowQrCode = (number) => {
    setSelectedNumber(number);
    setQrCode(`https://ec2-52-15-186-23.us-east-2.compute.amazonaws.com/scan/${number}`);
    setIsQrModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsQrModalOpen(false);
    setIsAddModalOpen(false);
  };

  const getStatusColor = (status) => {
    return status === 'Active' ? 'green.500' : 'red.500';
  };

  const getStatusText = (status) => {
    return status === 'Active' ? 'Ativado' : 'Desativado';
  };

  const getBackgroundStyle = () => {
    return theme === 'dark'
      ? { backgroundColor: '#1F2020', backgroundSize: 'cover' }
      : { backgroundColor: '#F7F7F7' };
  };

  const getCardBackgroundColor = () => {
    return theme === 'dark'
      ? { backgroundImage: "url('/img/bgshap.png')", backgroundSize: 'cover' }
      : { backgroundColor: '#e8e8e8' };
  };

  const getTextColor = () => {
    return theme === 'dark' ? 'white' : 'gray.800';
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  return (
    <Flex direction="column" minHeight="100vh" style={getBackgroundStyle()}>
      <Navbar />
      <Box className="boxgerals" flex="1" p={8} ml={{ base: 0, md: 280 }} mt={{ base: 16, md: 20 }}>
        <Flex className="boxgeralssx headingdobox" justifyContent="space-between" alignItems="center" mb={8}>
          <Flex alignItems="center">
            <Image src="/img/whats.svg" alt="WhatsApp" boxSize="40px" mr={2} />
            <Heading className="textsx" as="h2" size="lg" color={getTextColor()}>
              WhatsApp
            </Heading>
          </Flex>
          <Button
            variant="ghost"
            onClick={() => setIsAddModalOpen(true)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ background: 'none' }}
            _active={{ background: 'none' }}
          >
            <Icon as={FaPlus} boxSize={34} color="green.500" border="2px solid" borderRadius="full" padding="4px" />
            <Text className="montserrat color" marginLeft="8px" color="green.500">
              Adicionar WhatsApp
            </Text>
          </Button>
        </Flex>
        <SimpleGrid className="oks" columns={{ base: 1, md: 3 }} spacing={12}>
          {numbers.map((number) => (
            <GridItem key={number._id}>
              <Box
                style={getCardBackgroundColor()}
                borderRadius="md"
                p={6}
                className="padding"
                backgroundSize="cover"
                backgroundPosition="center"
                boxShadow="md"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="200px"
              >
                <Flex className="centerx" mb={4}>
                  <Image src="/img/flag/simplifica.png" alt="Número" borderRadius="full" mr={4} />
                  <Box className="centerxx">
                    <Text className="mobnumber" fontWeight="bold" fontSize="lg" color={getTextColor()}>
                      WhatsApp
                    </Text>
                    <Text className="mobnns" fontSize="md" color={getTextColor()}>
                      {formatPhoneNumber(number.number)}
                    </Text>
                  </Box>
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mt={2}>
                  <Flex alignItems="center">
                    <Box w={3} h={3} bg={getStatusColor(number.status)} borderRadius="full" mr={2} />
                    <Text className={`montserrat ${theme === 'dark' ? 'colors' : ''}`} color={getStatusColor(number.status)} fontWeight="bold">
                      {getStatusText(number.status)}
                    </Text>
                  </Flex>
                  <Flex gap={2}>
                    {number.status !== 'Active' && (
                      <Button
                        colorScheme="green"
                        onClick={() => handleShowQrCode(number.number)}
                      >
                        Ativar
                      </Button>
                    )}
                    <IconButton
                      icon={<FaTrash size={20} />}
                      aria-label="Excluir número"
                      onClick={() => openDeleteConfirmation(number._id)}
                      color="white"
                      variant="ghost"
                    />
                  </Flex>
                </Flex>
              </Box>
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={() => setIsAlertOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar Número
            </AlertDialogHeader>
            <AlertDialogBody>
              Tem certeza que deseja deletar este número? Esta ação não pode ser desfeita.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDeleteNumber} ml={3}>
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={isAddModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent style={{ backgroundColor: theme === 'dark' ? '#1F2020' : '#F7F7F7' }}>
          <ModalHeader>Adicionar Número</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>DDI</FormLabel>
              <Select className="border" value={ddi} onChange={(e) => setDdi(e.target.value)}>
                <option value="55">Brasil (+55)</option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Número de Telefone</FormLabel>
              <Input
                placeholder="Digite o número de telefone"
                className="border"
                value={newNumber}
                onChange={(e) => setNewNumber(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button className="noatuars" colorScheme="green" onClick={handleAddNumber}>
              Adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isQrModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Escanear QR Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <iframe src={qrCode} width="100%" height="400px" frameBorder="0" style={{ pointerEvents: 'none' }} />
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleCloseModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ManageNumbers;
