import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import { useAuth } from '../context/AuthContext';

const Home = () => {
  const { userName } = useAuth();
  console.log('User Name in Home:', userName); // Adicione este log

  return (
    <Box>
      <Navbar />
      <Heading>Bem-vindo ao Painel de Controle {userName}</Heading>
    </Box>
  );
};

export default Home;