// theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 400,  // Define o peso da fonte
        color: 'gray.700',
        lineHeight: 'tall',
        bg: 'gray.50',
      },
      a: {
        color: 'teal.500',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'md',
        _focus: { boxShadow: 'none' },
      },
      sizes: {
        md: {
          height: '48px',
          fontSize: 'lg',
        },
      },
      variants: {
        solid: {
          bg: 'teal.400',
          _hover: {
            bg: 'teal.600',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 'bold',
        fontSize: 'md',
      },
    },
  },
});

export default theme;
